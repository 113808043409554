import { useHistory } from 'react-router-dom';
import { MobileMenuHeader, DesktopMenuHeader } from '../../../components/MenuHeader';
import { PLUSOnDemandCounseling, PLUSOnDemandCounselingEAP } from '../../../assets';
import getPartnerResourceConfig from '.././partner-resource-config';
import { Spinner } from '../../../components/common';
import { useExternalProviderPlan } from '../../../hooks/useExternalProviderPlans';
import { ResourceDetailView } from '.././resource-detail';
import { BackgroundStripe } from '../../../components/mwa-3.5-redesign/bg-stripe';
import useAuth from '../../../hooks/useAuth';
import { defaultSsoLinkUrl, recuroUrl } from './recuro-url';

export const CrisisPage: React.FC = () => {
  const navigate = useHistory();
  const { user, isLoading: userIsLoading } = useAuth();
  const { data: providerPlans, isLoading: externalPlanLoading } = useExternalProviderPlan();
  const providerPlan =
    providerPlans?.find((plan) => {
      return plan.id === user?.caller_role.active_subscription?.package.external_plan_id;
    }) ?? null;
  const hasWorklife = !providerPlan?.provider_types.includes('crisis_lite');
  const partnerResourceConfig = getPartnerResourceConfig(user, providerPlan);

  const longDescriptionText = hasWorklife
    ? 'Your benefit includes 24/7 access to compassionate counseling and resources so you’re always supported when navigating life’s challenges. From day-to-day challenges to a sudden critical event, Care Managers are available 24/7 to help you with personal, family, and work-related concerns. You also have access to 3  free in-person or virtual visits with a  licensed behavioral health clinician per need, per year as well referrals to family support, financial planning, and legal resources.'
    : 'Your benefit includes 24/7 access to compassionate counseling and resources so you’re always supported when navigating life’s challenges. From day-to-day challenges to a sudden critical event, Care Managers are available 24/7 to help you with personal, family, and work-related concerns. You also have access to 3  free in-person or virtual visits with a  licensed behavioral health clinician per need, per year.';

  // TODO inconsistent mocks for External Provider page names -- on Counseling and Care homepage tile
  // they include the "+" but on the dedicated page mock it is omitted...looks weird for +Care so including it here
  const name = hasWorklife ? '+On-Demand Counseling & EAP' : '+On-Demand Counseling';
  const description = '24/7 access to Care Managers to help you navigate most health needs.';
  const longDescription = (
    <div className="flex flex-col gap-y-4">
      {longDescriptionText}
      <div>ONLINE ACCESS: Click the link above to schedule a visit online.</div>
      <div>
        PHONE ACCESS: Schedule a visit by phone by calling <div>855-6RECURO (855-673-2876)</div>
      </div>
    </div>
  );

  if (externalPlanLoading || userIsLoading) return <Spinner />;

  return (
    <div className="w-full min-h-screen overflow-auto bg-cover bg-neutral-700 bg-opacity-5 pb-20">
      <DesktopMenuHeader />
      <MobileMenuHeader onBack={() => navigate.goBack()} />
      <ResourceDetailView
        name={name}
        phoneNumber={providerPlan?.provider === 'recuro' ? '8556732876' : undefined}
        url={providerPlan?.provider === 'recuro' ? recuroUrl : defaultSsoLinkUrl}
        urlDescription={''}
        ctaButtonText={'Connect Now'}
        imageUrl={hasWorklife ? PLUSOnDemandCounselingEAP : PLUSOnDemandCounseling}
        // TODO Jason only provided tag names for +Virtual Primary Care, just guessing here
        tagNames={['Primary Care']}
        description={longDescription}
        clientLogoUrl={user?.caller_role.active_subscription?.package?.client?.comms_logo_file_url}
        partnerResourceConfig={partnerResourceConfig}
        recuro={providerPlan?.provider === 'recuro'}
      />
      <BackgroundStripe />
    </div>
  );
};
